import { useSelector } from 'react-redux'

import { getProfileFromMarketplace } from 'src/Helpers/Permissions'

const useGetPermissionProfile = () => {
  const {
    contextMarketplaceId,
    marketplaceId,
    permissions
  } = useSelector(({ marketplace, context, user }) => ({
    marketplaceId: marketplace.id,
    contextMarketplaceId: context.marketplace && context.marketplace.id,
    permissions: user.permissions
  }))

  const getProfile = () => {
    return getProfileFromMarketplace(contextMarketplaceId || marketplaceId, permissions)
  }

  return {
    getProfile
  }
}

export { useGetPermissionProfile }
