import { PanelApiSettingKeys } from 'src/Constants/PanelApiSettings'

export const getPanelSettings = (allSettings) => {
  return allSettings.reduce((prev, curr) => {
    if (curr.key && curr.value && PanelApiSettingKeys.includes(curr.key)) {
      let value = curr.value
      if (['0', '1'].includes(curr.value)) {
        value = !!+curr.value // Converts '0/1', 0/1, false/true to boolean
      } else if (prev[curr.key] !== undefined) {
        value = `${value},${prev[curr.key]}`
      }
      prev = {
        ...prev,
        [curr.key]: value
      }
    }
    return prev
  }, {})
}
