export const isAccountDataCompleted = ({
  taxpayer_id: taxpayerId,
  ein,
  birthdate,
  email,
  business_email: businessEmail,
  first_name: firstName,
  last_name: lastName,
  business_name: businessName,
  phone_number: phoneNumber,
  business_phone_number: businessPhoneNumber,
  statement_descriptor: statementDescriptor,
  address,
  business_address: businessAddress,
  owner,
  // eslint-disable-next-line no-unused-vars
  owner_address: ownerAddress
}) => {
  if (taxpayerId) {
    const { line1, line2, postal_code: postalCode, neighborhood, city, state } = address

    return (
      !!birthdate &&
      !!email &&
      !!(firstName && lastName) &&
      !!phoneNumber &&
      !!statementDescriptor &&
      !!(line1 && line2 && postalCode && neighborhood && city && state)
    ) // &&
  }

  if (ein) {
    const { businessLine1, businessLine2, postal_code: businessPostalCode, businessNeighborhood, businessCity, businessState } = businessAddress
    const {
      first_name: ownerFirstName,
      last_name: ownerLastName,
      ownerBirthdate,
      taxpayer_id: ownerTaxpayerId,
      phone_number: ownerPhoneNumber,
      ownerEmail,
      address: ownerAddress
    } = owner
    const {
      line1: ownerLine1,
      line2: ownerLine2,
      postal_code: ownerPostalCode,
      neighborhood: ownerNeighborhood,
      city: ownerCity,
      state: ownerState
    } = ownerAddress
    return (
      !!birthdate &&
      !!businessEmail &&
      !!businessName &&
      !!businessPhoneNumber &&
      !!statementDescriptor &&
      !!(businessLine1 && businessLine2 && businessPostalCode && businessNeighborhood && businessCity && businessState) &&
      !!(ownerFirstName && ownerLastName && ownerBirthdate && ownerTaxpayerId && ownerPhoneNumber && ownerEmail) &&
      !!(ownerAddress && ownerLine1 && ownerLine2 && ownerPostalCode && ownerNeighborhood && ownerCity && ownerState)
    )
  }

  return false
}

export const isDocumentsDataCompleted = (documents) => {
  return (
    !!documents.find(({ category, status }) => category === 'identificacao' && status !== 'failed' && status !== 'rejected') &&
    !!documents.find(({ category, status }) => category === 'residencia' && status !== 'failed' && status !== 'rejected') &&
    !!documents.find(({ category, status }) => category === 'atividade' && status !== 'failed' && status !== 'rejected')
  )
}

export const isBankAccountsDataCompleted = (bankAccounts) => {
  return !!(bankAccounts && bankAccounts.length > 0)
}
