export const getMarketplacesByPermission = (permissions) => {
  const profilesAllowed = ['full_access_admin', 'full_visualization', 'full_access_configuration', 'full_access_operation']
  const marketplaces = permissions.filter(
    item => (item.resource === 'layout' && item.service === 'dashboard' && item.action !== 'customers') ||
            (item.type === 'model' && profilesAllowed.indexOf(item['model_name']) !== -1)
  )
  return marketplaces
}

export const getProfileFromMarketplace = (marketplaceId, permissions) => {
  const marketplaces = permissions.filter(
    item => item.marketplace_id === marketplaceId &&
    item.type === 'model' &&
    item.model_name !== 'customers')

  return marketplaces && marketplaces.length ? marketplaces[0].model_name : null
}

export const getPermissionsFromGroup = (profilePermissions, groupPermissions) => {
  let permissions = []
  if (profilePermissions.length === 1 && profilePermissions[0] === '*') {
    for (let permission in groupPermissions) {
      permissions = permissions.concat(groupPermissions[permission])
    }
  } else {
    for (let i = 0, x = profilePermissions.length; i < x; ++i) {
      permissions = permissions.concat(groupPermissions[profilePermissions[i]] || [])
    }
  }

  return permissions
}
