import React, { Component } from 'react'
import toCss from 'to-css'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

class WhiteLabelStyles extends Component {
  createStyles = (styles) => {
    return toCss(styles, { indent: '  ' })
  }

  render () {
    const { styles } = this.props

    return (
      <Helmet>
        <style type='text/css'>{`${this.createStyles(styles)}`}</style>
      </Helmet>
    )
  }
}

WhiteLabelStyles.propTypes = {
  styles: PropTypes.object
}

export default WhiteLabelStyles
