import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { changeSellerContext } from 'src/Actions/context'
import { resetSellerContext } from '../Actions/context'

const useSellerContext = () => {
  const mathSellerRoute = useRouteMatch({ path: ['*/estabelecimento/:sellerId/*'] })
  const mathNotSellerRoute = useRouteMatch({ path: '/', exact: true })
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    seller,
    sellerContextChangeError,
    marketplace
  } = useSelector(({ context, marketplace }) => {
    return {
      seller: context.seller,
      sellerContextChangeError: context.sellerContextChangeError,
      marketplace
    }
  })

  const sellerIdParams = mathSellerRoute && mathSellerRoute.params && mathSellerRoute.params.sellerId

  useEffect(() => {
    if (marketplace.id && sellerIdParams && !sellerContextChangeError && sellerIdParams !== seller?.id) {
      dispatch(changeSellerContext(sellerIdParams))
    } else if (mathNotSellerRoute) {
      dispatch(resetSellerContext())
    }
  }, [sellerIdParams])

  useEffect(() => {
    if (sellerContextChangeError?.status === 404) {
      history.push('/not-found')
    }
  }, [sellerContextChangeError])
}

export { useSellerContext }
