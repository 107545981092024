import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

export const useTitle = (title) => {
  const marketplaceName = useSelector(
    ({ marketplace }) => marketplace?.details?.name
  )

  useEffect(() => {
    document.title = `${marketplaceName || 'Dashboard'}${title ? ' - ' + title : ''}`
  }, [title, marketplaceName])
}

export const Title = ({ children }) => {
  useTitle(children)
  return false
}

Title.propTypes = {
  children: PropTypes.node
}

export default Title
