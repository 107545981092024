export default {
  resource_not_found: {
    icon: '/assets/icons/actions/search.svg',
    title: 'Não encontramos esta venda',
    description: 'Verifique se o ID que você buscou está correto'
  },
  'The capture request failed. Transaction could not be captured.':
    'Não foi possível autorizar a venda',
  'Transactions with status canceled and with confirmed flag setted to 1 are not voidable':
    'Não foi possível cancelar a venda',
  'Not possible to void when transaction is not succeeded.':
    'Não foi possível cancelar a venda',
  'The void request failed. Transaction could not be voided.':
    'Essa transação não pode ser estornada'
}
