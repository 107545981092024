import { useState, useEffect } from 'react'

export function useTimer () {
  const [count, setCounter] = useState(0)

  useEffect(() => {
    if (count > 0) {
      setTimeout(() => setCounter((v) => v - 1), 1000)
    }
  }, [count])

  return {
    count,
    start: (seconds) => setCounter(seconds)
  }
}
