export default {
  server_api_error: 'Algo deu errado',
  'Invalid password in current_password.': 'Senha atual incorreta',
  'missing-input-secret': {
    title: 'Que pena! O tempo para conexão expirou.',
    message: 'Tente novamente.'
  },
  'invalid-input-secret': {
    title: 'Que pena! O tempo para conexão expirou.',
    message: 'Tente novamente.'
  },
  'missing-input-response': {
    title: 'Que pena! O tempo para conexão expirou.',
    message: 'Tente novamente.'
  },
  'invalid-input-response': {
    title: 'Que pena! O tempo para conexão expirou.',
    message: 'Tente novamente.'
  },
  'bad-request': {
    title: 'Que pena! O tempo para conexão expirou.',
    message: 'Tente novamente.'
  },
  'timeout-or-duplicate': {
    title: 'Que pena! O tempo para conexão expirou.',
    message: 'Tente novamente.'
  },
  'Unsafe score to proceed': {
    title: 'Marque o campo abaixo para prosseguir.',
    message: ''
  },
  'password between last 5 passwords':
    'A nova senha não pode ser igual às últimas 5 senhas. Por favor, escolha uma senha diferente para garantir a segurança da sua conta.',
  'Internal server error':
    'Não se preocupe, seus dados não foram afetados.\n Estamos trabalhando para evitar que se repita.',
  'Endpoint request timed out':
    'Não se preocupe, seus dados não foram afetados.\n Estamos trabalhando para evitar que se repita.'
}
