import queryString from 'query-string'

function pathExists (routes) {
  const { pathname } = window.location

  let pathExist = false

  for (let i = 0; i < routes.length; i++) {
    if (routes[i].path === pathname) {
      pathExist = true
      break
    }
  }

  return pathExist
}

function getRedirect () {
  const { search } = window.location

  return queryString.parse(search).redirect || null
}

function getQuerystring () {
  const { search } = window.location

  return queryString.parse(search)
}

export {
  pathExists,
  getRedirect,
  getQuerystring
}
